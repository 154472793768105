import React from "react";

import "../../assets/css/home.css";
import "../../assets/css/responsive.css";

const CircularEconomy = () => {

    return (

        <>
            <h5>Our Missions</h5>
            <p>Providing a waste management service that enables  company to manage waste efficiently and responsible.</p>
        </>

    );

};

export default CircularEconomy;