import React from "react";

import "../../assets/css/home.css";
import "../../assets/css/responsive.css";

const RenewableEnergy = () => {

    return (

        <>
            <h5>Our Missions</h5>
            <p>Deploying waste to energy project to convert waste into the best quality of renewable energy and high-value materials.</p>
        </>

    );

};

export default RenewableEnergy;