import React from "react";

import "../../assets/css/home.css";
import "../../assets/css/responsive.css";

const DigitalTransformation = () => {

    return (

        <>
            <h5>Our Missions</h5>
            <p>We help businesses adopt environmentally friendly practices to reduce their emission impact on the environment.</p>
        </>

    );

};

export default DigitalTransformation;