import React from "react";

import "../../assets/css/home.css";
import "../../assets/css/responsive.css";

const ClimateEmpowerment = () => {

    return (

        <>
            <h5>Our Missions</h5>
            <p>Developing waste community through education, empowerment, and climate action movement in a sustainable ecosystem.</p>
        </>

    );

};

export default ClimateEmpowerment;